// eslint-disable-next-line import/prefer-default-export
import i18n from '@/libs/i18n/index'
export function parseResp(res) {
  if (res.status === 200) {
    const resultState = res.data.state
    if (resultState) {
      return {
        data: res.data.data,
        success: true,
      }
    }

    const { message } = res.data
    const { code } = res.data
    return {
      errCode: code,
      message,
      success: false,
    }
  }
  return {
    errCode: -1,
    message: '操作失败',
    success: false,
  }
}

export function parseError(err) {
  let { message } = err
  if (message === 'Network Error') {
    message = i18n.t('taskManagerInfo.Sure')
  } else if (message.includes('timeout')) {
    message = i18n.t('taskManagerInfo.Sure')
  } else if (message.includes('Request failed with status code')) {
    message = `${i18n.t('taskManagerInfo.system interface')}${message.substr(message.length - 3)}${i18n.t('taskManagerInfo.abnormal')}`
  }
  return message
}
