<template>
  <div class="associations_logs">
    <div class="public-breadcrumb">
      <router-link style="color: #6e6b7b" to="/">{{
        $t("devices.Welcome")
      }}</router-link>
      > {{ $t('associationLog.logs') }} >
      <span style="color:#3461FF">{{ $t('associationLog.Associations logs') }}</span>
    </div>
    <div class="table_set">
      <div class="select_filter">
        <div>
          <span class="text">{{ $t('associationLog.Records') }}:{{ pageParams.total }}</span>
          <el-select
            v-model="pageParams.storeType"
            @change="getAssociationLogListReq"
            size="small"
          >
            <el-option
              v-for="item in storeQueryTypeList"
              :key="item.dictValue"
              :label="item.dictName"
              :value="item.dictValue"
            />
          </el-select>
        </div>
      </div>
      <el-table
        ref="tableRows"
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        class="table-topstyle"
        @select-all="handleSelectAll"
        @sort-change="handleSortChange"
      >
        <el-table-column
          type="selection"
          width="55"
        >
          <el-table-column type="selection" />
        </el-table-column>
        <el-table-column
          :label="$t('associationLog.Store Name')"
        >
          <el-table-column
            prop="storeName"
            width="200"
          >
            <template slot="header">
              <el-input
                v-model="pageParams.storeName"
                size="mini"
                @input="getAssociationLogListReq"
                clearable
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('associationLog.ESL Code')"
          prop="eslCode"
          sortable="custom"
        >
          <el-table-column
            prop="eslCode"
            width="200"
          >
            <template slot="header">
              <el-input
                v-model="pageParams.eslCode"
                size="mini"
                @input="getAssociationLogListReq"
                clearable
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('associationLog.Item Tag')"
          prop="layoutLumpTag"
          sortable="custom"
        >
          <el-table-column
            prop="layoutLumpTag"
            width="150"
          >
            <template slot="header">
              <el-input
                v-model="pageParams.layoutLumpTag"
                size="mini"
                @input="getAssociationLogListReq"
                clearable
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('associationLog.Item Code')"
          prop="itemCode"
          sortable="custom"
        >
          <el-table-column
            prop="itemCode"
            width="200"
          >
            <template slot="header">
              <el-input
                v-model="pageParams.itemCode"
                size="mini"
                @input="getAssociationLogListReq"
                clearable
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('associationLog.Update time')"
          prop="updateTime"
          sortable="custom"
        >
          <el-table-column prop="updateTime">
            <template slot="header">
              <el-date-picker
                v-model="pageParams.time"
                type="daterange"
                align="center"
                size="mini"
                unlink-panels
                range-separator="-"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                class="associationDatePicker"
                @change="getAssociationLogListReq"
                clearable
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('associationLog.Operator')"
          prop="operator"
          sortable="custom"
        >
          <el-table-column
            prop="operator"
            width="120"
          >
            <template slot="header">
              <el-input
                v-model="pageParams.operator"
                size="mini"
                @input="getAssociationLogListReq"
                clearable
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('associationLog.Type')"
        >
          <el-table-column
            prop="operateType"
            width="120"
          >
            <template slot="header">
              <el-select
                v-model="pageParams.operateType"
                placeholder=""
                size="mini"
                @change="getAssociationLogListReq"
                clearable
              >
                <el-option
                  v-for="item in operateTypeList"
                  :key="item.codeId"
                  :label="item.codeName"
                  :value="item.codeId"
                />
              </el-select>
            </template>
            <template slot-scope="scope">
              <el-tag
                v-if="scope.row.operateType==1701"
                class="association_link"
              >
                {{$t('associationLog.Link')}}
              </el-tag>
              <el-tag
                v-if="scope.row.operateType==1702"
                type="danger"
                class="association_unlink"
              >{{$t('associationLog.Unlink')}}</el-tag>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <!-- <div class="d-flex justify-content-between flex-wrap b_pag">
        <div class="d-flex align-items-center">
          <span class="text-nowrap">{{$t("pagination.Display")}}</span>
          <el-select
            v-model="pageParams.size"
            placeholder="请选择"
            @change="getAssociationLogListReq"
          >
            <el-option
              v-for="item in ['10','20','50','100','200','500','1000']"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
          <span class="text-nowrap">{{$t("pagination.Strip")}}</span>
        </div>
        <el-pagination
          background
          :page-size.sync="pageParams.size"
          :current-page.sync="pageParams.current"
          prev-text="Previous"
          next-text="Next"
          layout="prev, pager, next"
          :total="pageParams.total"
          @size-change="getAssociationLogListReq"
          @current-change="getAssociationLogListReq"
        />
      </div> -->
      <pagination :tableInfo="pageParams" @changePage="changePage" />
    </div>
    <!-- table -->
  </div>
</template>
<script>
// import { getAssociationLogListReq } from "../../../../../src/libs/api/logManger"

import { commonStore } from '@/store/global-select-store'

import modelUi from '@/views/taskManagement/taskManger/utils/modelUi.js'

import { getCodeList } from '@/libs/api/taskManger.js'

import { parseResp, parseError } from '@/views/taskManagement/taskManger/utils/requestUtil.js'

import { getStoreId } from '@/views/taskManagement/taskManger/utils/cacheUtil.js'
import pagination from '@/components/pagination'


export default {
  components: {
    pagination
  },
  data() {
    return {
      commonStore,
      operateTypeList: [],
      storeQueryTypeList: [
        {
          dictValue: 2,
          dictName: this.$t('ThisStore'),
        },
        {
          dictValue: 1,
          dictName: this.$t('ZimenStore'),
        },
        {
          dictValue: 0,
          dictName: this.$t('AllStore'),
        },
      ],
      leng: '',
      tableData: [],
      pageParams: {
        current: 1,
        size: 10,
        total: 20,
        storeType: 2,
        storeId: '',
        orders: [],
      },
    }
  },
  computed: {
    language() {
      return this.$store.state.appConfig.language
    }
  },
  created() {
    commonStore.setInjectCallBack(this.initPage)
    // this.getAssociationLogList()
    // this.getOperateTypeListReq()
    this.initPage()
  },
  watch: {
    language(val) {
      if(val) this.initPage()
    }
  },
  methods: {
    initPage() {
      this.getAssociationLogList()
      this.getOperateTypeListReq()
    },
    changePage() {
      this.getAssociationLogListReq()
    },
    handleSortChange(v1) {
      const { prop } = v1
      const { order } = v1
      let orderStr = ''
      if (order === 'descending') {
        orderStr = 'desc'
      }
      if (order === 'ascending') {
        orderStr = 'asc'
      }

      let keyName = ''
      if (prop === 'eslCode') {
        keyName = 'esl_code'
      }
      if (prop === 'layoutLumpTag') {
        keyName = 'layout_lump_tag'
      }
      if (prop === 'itemCode') {
        keyName = 'item_code'
      }
      if (prop === 'updateTime') {
        keyName = 'update_time'
      }
      if (prop === 'operator') {
        keyName = 'operator'
      }

      if (orderStr) {
        const orders = []
        const orderItem = `${orderStr}:${keyName}`
        orders.push(orderItem)
        this.pageParams.orders = orders
      } else {
        this.pageParams.orders = []
      }
      this.getAssociationLogListReq()
    },

    getOperateTypeListReq() {
      getCodeList(17)
        .then(res => {
          const result = parseResp(res)
          if (result.success) {
            this.operateTypeList = result.data
          } else {
            modelUi.msgError(result.message)
          }
        })
        .catch(err => {
          modelUi.msgError(parseError(err))
        })
    },

    selection() {
      return this.$refs.tableRows && this.$refs.tableRows.selection.length
    },
    handleSelectAll() {
      const { isAllSelected } = this.$refs.tableRows.store.states
      console.log('selection==', this.$refs.tableRows.store.states.isAllSelected)
      if (isAllSelected) {
        const arr = []
        selection.forEach(it => {
          arr.push(...it.children)
        })
        this.selectChild(arr)
      } else {
        this.$refs.tableRows.clearSelection()
      }
    },
    // 获取列表数据
    getAssociationLogListReq() {
      this.getAssociationLogList()
    },
    async getAssociationLogList() {
      const data = {
        storeName: this.pageParams.storeName,
        eslCode: this.pageParams.eslCode,
        layoutLumpTag: this.pageParams.layoutLumpTag,
        itemCode: this.pageParams.itemCode,
        operator: this.pageParams.operator,
        storeId: getStoreId() || 1,
        storeType: this.pageParams.storeType,
        startDate: this.pageParams.time ? this.pageParams.time[0] : null,
        endDate: this.pageParams.time ? this.pageParams.time[1] : null,
        current: this.pageParams.current,
        size: this.pageParams.size,
        operateType: this.pageParams.operateType,
        orders: this.pageParams.orders,
      }
      const res = await this.$Api.getAssociationLogList(data)
      this.rows = []
      this.leng = res.data.total
      this.pageParams.total = res.data.total
      this.tableData = res.data.records
    },
  },
}
</script>

<style lang="scss" scoped>
// .storelistrom {
// padding: 0 10px 10px;
// }

.association_unlink {
  background-color: #FECACA ;
  color: #c75d5d;
  border-radius: 15px;
  width: 79px;
  height: 30px;
  font-size: 14px;
  color: #871515;
}
.association_link {
  background-color: #c3e8ff;
  color: #c75d5d;
  // font-weight: 700;
  border-radius: 15px;
  width: 79px;
  height: 30px;
  color: #07334B;
  font-size: 14px;
}
.txtalein {
  padding-bottom: 20px;
  color: #000;
  font-size: 12px;
}
.handleBtn {
  display: flex;
  justify-content: space-between;
}
::v-deep .hideClass {
  visibility: hidden;
}
.icon {
  padding: 6px;
  cursor: pointer;
  .svg-icon {
    width: 23px;
    height: 23px;
  }
}
.text {
  padding: 0 15px;
  font-size: 12px;
}
.table_set {
  padding: 15px 0 0;
  border-radius: 6px;
  background-color: white;
  .select_filter {
    padding-bottom: 15px;
    .table_label_size {
      width: 160px;
      font-size: 16px;
    }
    .table_select {
      width: 214px;
    }
  }
  .icon {
    padding: 6px;
    cursor: pointer;
    .svg-icon {
      width: 23px;
      height: 23px;
    }
  }
  .b_pag {
    // padding: 0 35px;
  }
  .tb_checkbox {
    vertical-align: middle;
  }
}
</style>
