// eslint-disable-next-line import/prefer-default-export
export function getStoreId() {
  const userData = localStorage.getItem('userData')
  const storeId = localStorage.getItem('storeId')
  if (userData) {
    const userDataJSON = JSON.parse(userData)
    if (userDataJSON && userDataJSON.storeId) {
      return storeId || userDataJSON.storeId
    }
  }
  return ''
}
export function getUserType() {
  const userData = localStorage.getItem('userData')
  if (userData) {
    const userDataJSON = JSON.parse(userData)
    if (userDataJSON && userDataJSON.userType) {
      return userDataJSON.userType
    }
  }
  return ''
}

export function getToken() {
  const userData = localStorage.getItem('userData')
  if (userData) {
    const userDataJSON = JSON.parse(userData)
    if (userDataJSON && userDataJSON.token) {
      return userDataJSON.token
    }
  }
  return ''
}

export function getMerchantId() {
  const userData = localStorage.getItem('userData')
  if (userData) {
    const userDataJSON = JSON.parse(userData)
    if (userDataJSON && userDataJSON.merchantId) {
      return userDataJSON.merchantId
    }
  }
  return ''
}

// export function getSelectedStoreId() {
//   const storeObj = localStorage.getItem('storeObj')
//   if (storeObj) {
//     const storeObjJson = JSON.parse(storeObj)
//     if (storeObjJson && storeObjJson.storeId) {
//       return storeObjJson.storeId
//     }
//     return getStoreId()
//   }
//   return getStoreId()
// }
export function getSelectedStoreId() {
  const storeId = localStorage.getItem('storeId')
  return storeId
}
